import React from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CallToAction from "../components/CallToAction"
import {
  SectionPage,
  Container,
  SectionDescription,
  SectionTitle,
  BreadCrumb,
} from "../components/Section"
import MapSection from "../sections/About/MapSection"
import AddressIcon from "../components/Icons/AddressIcon"
import EmailIcon from "../components/Icons/EmailIcon"
import CallPhone from "../components/Icons/CallPhone"

const AddressGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  height: 200px;
  @media (max-width: 768px) {
    height: 750px;
    margin-top: 30px;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 20px;
  }
  @media (max-width: 600px) {
    height: 600px;
    grid-row-gap: 10px;
  }
  @media (max-width: 440px) {
    height: 500px;
    grid-row-gap: 0px;
  }
`
const GridItem = styled.div`
  padding: 10%;
  border: 1px solid #98dfff;
  & :hover {
    box-shadow: 0px 15px 45px rgba(152, 223, 255, 0.2);
    border-radius: 5px;
    padding: 15% 10%;
    cursor: pointer;
  }
`
const Icon = styled.div`
  width: 20%;
  display: inline-block;
  vertical-align: middle;
  & svg {
    width: 85%;
  }
  @media (max-width: 1100px) {
    width: 15%;
  }
`
const TextWrap = styled.div`
  width: 80%;
  display: inline-block;
  vertical-align: middle;
  & h4 {
    font-size: 24px;
    line-height: 29px;
    color: #062c44;
    margin-bottom: 5px;
  }
  & p {
    text-align: left;
    font-size: 16px;
    line-height: 22px;
  }
  @media (max-width: 1100px) {
    width: 80%;
    margin-left: 5%;
  }
`

const ServiceLocationPage = pageData => {
  const data = pageData.data.contentfulServiceLocation
  const AticleSectionData =
    pageData.data.contentfulServiceLocation.articleSection
  const ArticleData = pageData.data.allContentfulArticle
  let currentData = []
  const CardData = pageData.data.contentfulHome.cardSection
  const AllProducts = pageData.data.allContentfulProduct.edges.filter(
    edge =>
      edge.node.productCategory &&
      edge.node.productCategory.name &&
      edge.node.productRoofType &&
      edge.node.productRoofType.name
  )

  return (
    <Layout location={pageData.location}>
      <SEO title={data.metaTitle} description={data.metaDescription} />
      <SectionPage
        ept="160px"
        epb="0"
        xpt="160px"
        xpb="0"
        pt="100px"
        pb="0"
        bg="#fff"
      >
        <Container>
          <BreadCrumb className="static">
            <Link to="/">Home</Link> / <span>Service Location </span>
          </BreadCrumb>
          {/* <SectionPageTitle mb="30px" textAlign="center">{data.heroTitle}</SectionPageTitle> */}
          {/* <SectionDescription textAlign="center"
            dangerouslySetInnerHTML={{
              __html: data.heroDescription.childMarkdownRemark.html,
            }}
          /> */}
        </Container>
        <MapSection
          stateName={data.mapSection.stateName}
          mapImage={data.mapSection.mapImage}
          cities={data.mapSection.cities}
        />
      </SectionPage>

      {/* <CardSection data={CardData} productData={AllProducts} /> */}

      <SectionPage
        ept="80px"
        epb="80px"
        xpt="40px"
        xpb="40px"
        pt="30px"
        pb="30px"
      >
        <Container>
          <SectionTitle>{data.ourOffice.title}</SectionTitle>
          <SectionDescription
            maxWidth="720px"
            textAlign="center"
            dangerouslySetInnerHTML={{
              __html: data.ourOffice.description.childMarkdownRemark.html,
            }}
          />
          {data.ourOffice.features.map(content => {
            return (
              <div>
                {/* <SectionTitle>{content.title}</SectionTitle> */}
                <AddressGrid>
                  <GridItem>
                    <Icon>
                      <AddressIcon />
                    </Icon>
                    <TextWrap>
                      <h4>Address</h4>
                      <p>{content.address}</p>
                    </TextWrap>
                  </GridItem>
                  <GridItem>
                    <a href={`mailto:${content.email}`}>
                      <Icon>
                        <EmailIcon />
                      </Icon>
                      <TextWrap>
                        <h4>Email Address</h4>
                        <p>{content.email}</p>
                      </TextWrap>
                    </a>
                  </GridItem>
                  <GridItem>
                    <a href={`tel:${content.phone}`}>
                      <Icon>
                        <CallPhone />
                      </Icon>
                      <TextWrap>
                        <h4>Contact Number</h4>
                        <p>{content.phone}</p>
                      </TextWrap>
                    </a>
                  </GridItem>
                </AddressGrid>
              </div>
            )
          })}
        </Container>
      </SectionPage>
      {/* <ArticleCarousel sectionData={AticleSectionData} data={ArticleData} /> */}
      <CallToAction />
    </Layout>
  )
}

export default ServiceLocationPage

export const pageQuery = graphql`
  query ServiceLocationPageQuery {
    contentfulServiceLocation {
      title
      metaTitle
      metaDescription
      heroTitle
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      ourOffice {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        features {
          title
          address
          email
          phone
        }
      }
      articleSection {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
      }
      mapSection {
        stateName
        mapImage {
          fluid(maxWidth: 200, quality: 100) {
            base64
            sizes
            aspectRatio
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
        cities {
          cityName
          url
        }
      }
    }
    contentfulHome {
      cardSection {
        title
        description {
          description
        }
      }
    }
    allContentfulProduct(sort: { fields: skuNumber }) {
      edges {
        node {
          id
          senseiProductId
          skuNumber
          imageSrc
          image {
            fluid(maxWidth: 700, quality: 100) {
              base64
              sizes
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          title
          width
          length
          height
          price
          priceDescription
          gauge
          productServices {
            service
            image {
              fluid(maxWidth: 45, quality: 100) {
                aspectRatio
                base64
                sizes
                src
                srcWebp
                srcSetWebp
                srcSet
              }
            }
          }
          productRoofType {
            name
          }
          productCategory {
            name
          }
          description {
            description
          }
          numberOfSale
          canBeUsedAs {
            content
          }
        }
      }
    }
    allContentfulArticle {
      edges {
        node {
          title
          publishDate(formatString: "DD, MMMM YYYY")
          description {
            description
          }
          tumbnail {
            fluid(maxHeight: 700) {
              base64
              sizes
              src
              srcSet
              srcWebp
              srcSetWebp
              aspectRatio
            }
          }
          category {
            name
          }
          createdAt(fromNow: true)
        }
      }
    }
  }
`
